















import { Component, Prop, Vue } from 'vue-property-decorator'

import Attachment from '@/models/attachment'

import FileUploader from './components/FileUploader'
import AttachmentsTable from './components/AttachmentsTable'

Vue.component('FileUploader', FileUploader)
Vue.component('AttachmentsTable', AttachmentsTable)

@Component
export default class AppFileManager extends Vue {
  @Prop() associatedType!: string
  @Prop() associatedId!: string

  attachments: Attachment[] = []

  mounted () {
    this.getAttachments()
  }

  async getAttachments () {
    this.attachments = (await Attachment
      .where({
        recordId: this.associatedId,
        recordType: this.associatedType
      })
      .order({ createdAt: 'desc' })
      .includes('blob')
      .per(10000)
      .all()
    ).data
  }
}
