







































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

Vue.component('Button', () => import('primevue/button'))

@Component
export default class ValuationActionButtons extends Vue {
  @Prop() valuation!: any
  @Prop() readMode!: boolean
  @Prop() isCalculatorOpen!: boolean
  @Prop() isEditRowValid!: boolean

  @Emit()
  enableEditRow () { return this.valuation }

  @Emit()
  openDeleteDialog () { return this.valuation }

  @Emit()
  cancelEditRow () { return this.valuation }

  @Emit()
  saveValuation () { return this.valuation }
}
