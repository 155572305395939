var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"operation-actions-wrapper"}},[_c('Button',{staticClass:"p-button-outlined",class:{
    'p-button-info': _vm.isTopRowValid || !_vm.isTopRow,
    'p-button-secondary': _vm.isTopRow
  },attrs:{"icon":!_vm.menuOpen ? 'pi pi-angle-down' : '',"aria-controls":"overlay_menu","aria-haspopup":"true","label":"Actions","iconPos":"right"},on:{"click":_vm.toggleActionItems}}),_c('Menu',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.showMenuTooltip),expression:"showMenuTooltip",modifiers:{"bottom":true}}],ref:_vm.refKey,attrs:{"id":"overlay_menu","appendTo":"body","popup":true,"model":[
    {
      label: 'Insert Above',
      icon: 'pi pi-arrow-circle-up',
      disabled: _vm.isTopRow,
      command: function () { return _vm.insertRow(_vm.rowIndex); }
    },
    {
      label: 'Insert Below',
      icon: 'pi pi-arrow-circle-down',
      disabled: _vm.insertBelowDisabled,
      command: function () { return _vm.insertRow(_vm.rowIndex + 1); }
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      disabled: _vm.isTopRow,
      command: function () { return _vm.deleteThisRow(); }
    }
  ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }