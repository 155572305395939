













































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'

import Attachment from '@/models/attachment'

import AppDialog from '@/components/AppDialog'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'

Vue.component('AppDialog', AppDialog)
Vue.component('DataTable', DataTable)
Vue.component('Column', Column)
Vue.component('Button', Button)

@Component
export default class AttachmentsTable extends Vue {
  @Prop() attachments!: Attachment[]

  showAttachmentDeleteDialog = false
  attachmentPendingDelete!: Attachment

  async downloadAttachment (attachment: Attachment) {
    if (!attachment.id) return
    const serviceUrl = (await Attachment
      .selectExtra(['serviceUrl'])
      .find(attachment.id)
    ).data.serviceUrl
    const filename = attachment.blob.filename

    this.downloadLinkedFile(serviceUrl, filename)
  }

  promptForDeleteAttachment (attachment: Attachment) {
    this.attachmentPendingDelete = attachment
    this.showAttachmentDeleteDialog = true
  }

  @Emit()
  async deleteAttachment () {
    await this.attachmentPendingDelete.destroy()
    this.showAttachmentDeleteDialog = false
  }

  downloadLinkedFile (linkUrl: string, filename: string) {
    const link = document.createElement('a')
    link.setAttribute('href', linkUrl)
    link.setAttribute('download', filename)
    link.setAttribute('target', '_')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
