
























import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'

import Attachment from '@/models/attachment'

import FileUpload from 'primevue/fileupload'
Vue.component('FileUpload', FileUpload)

const Auth = namespace('auth')

@Component
export default class FileUploader extends Vue {
  @Prop() associatedType!: string
  @Prop() associatedId!: string

  @Auth.Getter uid!: string
  @Auth.Getter client!: string
  @Auth.Getter accessToken!: string

  maxFileSize = 50000000

  get invalidFileSizeMessaage () {
    const mb = this.maxFileSize / 1000 / 1000
    return `{0}: Invalid file size. Files should be smaller than ${mb}MB`
  }

  get uploadUrl () {
    return Attachment.baseUrl + '/api/v1/attachments'
  }

  @Emit()
  uploadAttachment (event: any) {
    const summaryMessage = 'Upload Complete'
    const detailMessage = event.files.length + ' file(s) uploaded'
    this.$toast.add({ severity: 'success', summary: summaryMessage, detail: detailMessage, life: 3000 })
  }

  setHeaders (event: any) {
    event.xhr.setRequestHeader('uid', this.uid)
    event.xhr.setRequestHeader('client', this.client)
    event.xhr.setRequestHeader('access-token', this.accessToken)
  }

  setAssociation (event: any) {
    event.formData.append('record_type', this.associatedType)
    event.formData.append('record_id', this.associatedId)
  }

  onUploadError (event: any) {
    alert('Error During Upload. Please Try Again.')
  }
}
