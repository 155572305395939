


































































































import { Component, Vue } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'

import Investment from '@/models/investment'

import Listbox from 'primevue/listbox'
import Button from 'primevue/button'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import InvestmentDetailSection from './components/InvestmentDetailSection'
import AppInvestmentTransactionsTable from '@/components/AppInvestmentTransactionsTable'
import InvestmentValuationsSection from './components/InvestmentValuationsSection'

Vue.component('Listbox', Listbox)
Vue.component('Button', Button)
Vue.component('TabView', TabView)
Vue.component('TabPanel', TabPanel)
Vue.component('InvestmentDetailSection', InvestmentDetailSection)
Vue.component('AppInvestmentTransactionsTable', AppInvestmentTransactionsTable)
Vue.component('InvestmentValuationsSection', InvestmentValuationsSection)

const Auth = namespace('auth')

@Component
export default class Investments extends Vue {
  @Auth.Getter currentPermissions!: string

  investments: Investment[] = []
  selectedInvestment: Investment | null = null
  newMode = false

  activeTab = 'Investment Information'
  tabStatuses = [true, false, false]
  showExitedInvestments = false

  enableCreate = false
  enableReadInvestment = false
  enableReadInvestmentTransaction = false
  enableCreateInvestmentTransaction = false
  enableUpdateInvestmentTransaction = false
  enableDestroyInvestmentTransaction = false

  get filteredInvestments () {
    return this.investments.filter(item => {
      return this.showExitedInvestments || (!this.showExitedInvestments && item.active)
    })
  }

  mounted () {
    this.getCurrentPermissions()
    this.getInvestments()
  }

  getCurrentPermissions () {
    const permissions = this.currentPermissions
    if (!permissions) return

    this.enableCreate = permissions.includes('create_investment')
    this.enableReadInvestment = permissions.includes('read_investment')
    this.enableReadInvestmentTransaction = permissions.includes('read_investment_transaction')
    this.enableCreateInvestmentTransaction = permissions.includes('create_investment_transaction')
    this.enableUpdateInvestmentTransaction = permissions.includes('update_investment_transaction')
    this.enableDestroyInvestmentTransaction = permissions.includes('destroy_investment_transaction')
  }

  async getInvestments () {
    if (!this.enableReadInvestment) return

    let investments = (await Investment
      .order({ active: 'desc' })
      .order('name')
      .per(1000)
      .all()
    ).data

    investments = this.insertActiveExitedSeparator(investments)
    this.investments = investments
  }

  insertActiveExitedSeparator (investments: any[]) {
    let idx = -1
    for (let i = 0; i < investments.length; i++) {
      const investment = investments[i]
      if (!investment.active) {
        idx = i
        break
      }
    }
    if (idx >= 0) {
      investments.splice(idx, 0, new Investment({
        name: 'Exited Investments',
        active: true
      }))
    }
    return investments
  }

  async refreshSelectedInvestment (investment: Investment | null = null) {
    this.newMode = false
    await this.getInvestments()

    if (!investment) investment = this.selectedInvestment
    if (!investment) return
    if (!investment.id) return

    Vue.nextTick(() => {
      const foundInvestment = this.investments.filter(item => {
        if (!investment) return false
        return item.id === investment.id
      })[0]
      this.$set(this, 'selectedInvestment', foundInvestment)
    })
  }

  onTabChange (event: { tab: { header: string } }) {
    this.activeTab = (event.tab.header === 'Loan Details' ? 'Contributions & Distributions' : event.tab.header)
    if (this.activeTab === 'Investment Information') this.refreshSelectedInvestment()
  }

  resetToFirstTab () {
    this.activeTab = 'Investment Information'
    this.$set(this.tabStatuses, 0, true)
    this.$set(this.tabStatuses, 1, false)
    this.$set(this.tabStatuses, 2, false)
  }

  onClickAddNew () {
    this.newMode = true
    this.$set(this, 'selectedInvestment', new Investment())
    this.resetToFirstTab()
  }

  toggleExitedInvestments () {
    this.showExitedInvestments = !this.showExitedInvestments
  }
}
