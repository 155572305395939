



































































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import { Valuation, Operation, MethodTemplate } from '@/models'

import Row from 'primevue/row'
import Button from 'primevue/button'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import DataTable from 'primevue/datatable'
import ColumnGroup from 'primevue/columngroup'

import AppDialog from '@/components/AppDialog'
import CalculatorActions from './CalculatorActions.vue'
import CalculatorAmountInput from './CalculatorAmountInput.vue'
import CalculatorButtonGroup from './CalculatorButtonGroup.vue'
import CalculatorCloseButton from './CalculatorCloseButton.vue'
import CalculatorSubtotalInput from './CalculatorSubtotalInput.vue'
import CalculatorTemplateDropdown from './CalculatorTemplateDropdown.vue'

import charactersUpTo from '@/utils/charactersUpTo'

Vue.component('Row', Row)
Vue.component('Button', Button)
Vue.component('Column', Column)
Vue.component('InputText', InputText)
Vue.component('DataTable', DataTable)
Vue.component('AppDialog', AppDialog)
Vue.component('ColumnGroup', ColumnGroup)
Vue.component('CalculatorActions', CalculatorActions)
Vue.component('CalculatorAmountInput', CalculatorAmountInput)
Vue.component('CalculatorButtonGroup', CalculatorButtonGroup)
Vue.component('CalculatorCloseButton', CalculatorCloseButton)
Vue.component('CalculatorSubtotalInput', CalculatorSubtotalInput)
Vue.component('CalculatorTemplateDropdown', CalculatorTemplateDropdown)

@Component
export default class Calculator extends Vue {
  @Prop() valuation!: Valuation

  operations: Operation[] = []
  initialOperationsCopy: Operation[] = []
  initialMethodTemplate!: MethodTemplate | null

  methodTemplates: MethodTemplate[] = []

  totalValue = 0
  subtotals = []
  valuationId = this.valuation.id
  showUnsavedChangesDialog = false
  showClearCalculatorDialog = false
  isNewValuationCalculator = !this.valuationId

  async mounted () {
    this.configureCalculator()
  }

  async configureCalculator () {
    this.methodTemplates = (await MethodTemplate.includes('operations').all()).data
    this.initialMethodTemplate = this.valuation?.methodTemplate || null
    await this.getOperations()
  }

  async getOperations () {
    this.isNewValuationCalculator
      ? this.getOperationsForNewValuation()
      : await this.getOperationsForExistingValuation()
  }

  getOperationsForNewValuation () {
    const unsavedOperationsExist = this.valuation.operations.length > 0
    if (unsavedOperationsExist) {
      this.operations = [...this.valuation.operations]
      this.initialOperationsCopy = Operation.duplicateRecords([...this.operations])
    } else {
      this.initialOperationsCopy = []
      this.operations = [Operation.makeDefaultRecord({ operationalType: 'Valuation' })]
    }
  }

  async getOperationsForExistingValuation () {
    this.operations = (await Operation
      .where({ operationalId: this.valuation.id! })
      .order('sequenceIndex')
      .all())
      .data

    if (!this.operations.length) {
      this.operations = [
        Operation.makeDefaultRecord({ operationalType: 'Valuation', operationalId: this.valuation.id! })
      ]
    }
    this.initialOperationsCopy = Operation.duplicateRecords([...this.operations])
  }

  onLabelInput (operation: Operation) {
    operation.label = charactersUpTo(operation.label, 20)
  }

  onSelectTemplate (newValuationOperations: Operation[]) {
    this.operations = newValuationOperations
  }

  restoreOriginalMethodTemplate () {
    if (this.initialMethodTemplate) {
      this.valuation.methodTemplate = this.initialMethodTemplate
      this.valuation.methodTemplateId = this.initialMethodTemplate.id
    } else {
      this.valuation.removeTemplateReferences()
    }
  }

  @Emit()
  onNewValuationCalculatorSaved (saveableOperations: Operation[]) {
    this.initialOperationsCopy = Operation.duplicateRecords([...saveableOperations])
    if (this.valuation?.methodTemplateId) {
      this.initialMethodTemplate = this.valuation.methodTemplate!
    }
    return [saveableOperations, this.totalValue]
  }

  @Emit()
  saveOperations () {
    return this.totalValue
  }

  @Emit()
  closeOverlayWithoutSaving () {
    this.showUnsavedChangesDialog = false
    this.restoreOriginalMethodTemplate()
    if (this.valuation?.id) {
      this.valuation.restoreInitialState()
    } else {
      this.valuation.operations = [...this.initialOperationsCopy]
    }
    this.closeOverlay()
  }

  @Emit()
  closeOverlay () { return null }

  @Emit()
  async clearCalculator () {
    await Operation.deleteRecords(this.operations)
  }
}
