












import { ButtonState, ButtonStates } from '@/types/valuations'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Valuation, Operation } from '@/models'

import Button from 'primevue/button'
Vue.component('Button', Button)

@Component
export default class CalculatorOperatorButton extends Vue {
  @Prop() operation!: Operation
  @Prop() valuation!: Valuation
  @Prop() isTopRow!: boolean

  buttonState!: ButtonState

  buttonStates: ButtonStates = [
    { operator: '+', icon: 'pi pi-plus' },
    { operator: '-', icon: 'pi pi-minus' },
    { operator: '*', icon: 'pi pi-times' },
    { operator: '/', icon: 'pi pi-percentage' }
  ]

  mounted () { this.buttonState = this.currentButtonState }

  updated () { this.buttonState = this.currentButtonState }

  get currentButtonStateIndex () {
    return this.buttonStates.findIndex(state => state.operator === this.operation.operator)
  }

  get currentButtonState (): ButtonState {
    return this.buttonStates[this.currentButtonStateIndex]
  }

  setNextButtonState () {
    this.buttonState = this.currentButtonStateIndex === this.buttonStates.length - 1
      ? this.buttonStates[0]
      : this.buttonStates[this.currentButtonStateIndex + 1]
    this.operation.operator = this.buttonState.operator
  }

  onOperatorClick () {
    this.valuation.removeTemplateReferences()
    this.setNextButtonState()
  }
}
