































































































































































































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'

import dayjs from 'dayjs'

import { Investment } from '@/models/investment'

import AppInputCurrency from '@/components/AppInputCurrency'
import Fieldset from 'primevue/fieldset'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Calendar from 'primevue/calendar'

Vue.component('AppInputCurrency', AppInputCurrency)
Vue.component('Fieldset', Fieldset)
Vue.component('Dropdown', Dropdown)
Vue.component('Button', Button)
Vue.component('InputText', InputText)
Vue.component('InputNumber', InputNumber)
Vue.component('Calendar', Calendar)

@Component
export default class InvestmentForm extends Vue {
  @Prop() investment!: Investment

  editedInvestment: Investment = new Investment()

  investmentTypes = [
    'agriculture',
    'equity',
    'lending',
    'real_estate'
  ]

  processing = false
  errors: string[] = []

  get invalid () {
    if (!this.editedInvestment.name) return true
    if (!this.editedInvestment.investmentType) return true
    return false
  }

  mounted () {
    this.duplicateOriginalInvestmentForEditing()
    this.preFormatDateFields()
  }

  async save () {
    this.processing = true
    const success = await this.editedInvestment.save()

    if (!success) {
      this.processing = false
      this.errors = [String(this.editedInvestment.errors)]
      return
    }

    this.saveComplete(this.editedInvestment)
    this.processing = false
  }

  duplicateOriginalInvestmentForEditing () {
    this.editedInvestment = new Investment(this.investment)
    if (this.investment.id !== undefined) {
      this.editedInvestment.isPersisted = true
    }
  }

  preFormatDateFields () {
    if (this.editedInvestment.investmentDate) {
      this.editedInvestment.investmentDate = new Date(dayjs(this.editedInvestment.investmentDate).format('MM/DD/YYYY'))
    }
    if (this.editedInvestment.exitDate) {
      this.editedInvestment.exitDate = new Date(dayjs(this.editedInvestment.exitDate).format('MM/DD/YYYY'))
    }
  }

  clearErrors () {
    this.errors = []
  }

  @Emit()
  cancel (investment: Investment = this.investment) {
    return investment
  }

  @Emit()
  saveComplete (investment: Investment) {
    return investment
  }
}
