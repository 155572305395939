import { render, staticRenderFns } from "./InvestmentContent.vue?vue&type=template&id=17a94076&scoped=true&"
import script from "./InvestmentContent.vue?vue&type=script&lang=ts&"
export * from "./InvestmentContent.vue?vue&type=script&lang=ts&"
import style0 from "./InvestmentContent.vue?vue&type=style&index=0&id=17a94076&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17a94076",
  null
  
)

export default component.exports