































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import Investment from '@/models/investment'

import Fieldset from 'primevue/fieldset'
import Tooltip from 'primevue/tooltip'
import Valuation from '@/models/valuation'

import InvestmentContentGridCell from './InvestmentContentGridCell.vue'
Vue.component('InvestmentContentGridCell', InvestmentContentGridCell)

Vue.component('Fieldset', Fieldset)
Vue.directive('tooltip', Tooltip)

@Component
export default class InvestmentContent extends Vue {
  @Prop() investment!: Investment

  flaggedValuation = new Valuation({ date: '' })

  mounted () { this.getFlaggedValuation() }

  async getFlaggedValuation () {
    if (this.investment.flaggedValuationId) {
      this.flaggedValuation = (await Valuation.find(this.investment.flaggedValuationId)).data
    }
  }

  get netInvestmentAmount () {
    return this.totalDistributions + this.totalContributions
  }

  get totalContributions () {
    return -1 * (this.investment.totalContributions || 0.0)
  }

  get totalDistributions () {
    return 1 * (this.investment.totalDistributions || 0.0)
  }

  get lastValuationDate () {
    return null
  }

  get lastValuationSource () {
    return null
  }

  @Watch('investment.id')
  async getNewFlaggedValuation () {
    this.flaggedValuation = new Valuation({ date: '' })
    await this.getFlaggedValuation()
  }
}
