



















import { PaginationState, ResetTablePayload, TablePageState } from '@/types/valuations'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import formatAppDate from '@/utils/formatAppDate'
import { Investment, Valuation } from '@/models'
import dayjs from 'dayjs'

import ValuationsTable from './ValuationsTable.vue'
Vue.component('ValuationsTable', ValuationsTable)

@Component
export default class InvestmentValuationsSection extends Vue {
  @Prop() investment!: Investment

  loading = true

  newValuation!: Valuation
  mostRecentlyDatedValuation: Valuation | null = null
  flaggedValuation: Valuation | null = null
  valuations: Valuation[] = []
  totalRecords = 0
  totalPages = 0

  paginationState!: PaginationState
  initialPaginationState = {
    recordsPerPage: 50,
    currentPage: 1,
    sortOrder: -1
  }

  async beforeMount () {
    await this.setTableData(this.initialPaginationState)
  }

  async setTableData (paginationState: PaginationState) {
    this.loading = true
    this.newValuation = Valuation.makeDefaultRecord(this.investment.id!)
    this.mostRecentlyDatedValuation = await Valuation.getByMostRecentDateAttr(this.investment.id!)
    if (!this.investment.flaggedValuationId) {
      await this.flagMostRecentValuation()
    }
    const tablePageState = await this.getValuations(paginationState)
    this.updateTablePageState(tablePageState)
    this.setFlaggedValuation()
    this.loading = false
  }

  async getValuations (paginationState: PaginationState): Promise<TablePageState> {
    if (!paginationState) paginationState = this.paginationState
    this.paginationState = paginationState

    const { recordsPerPage, currentPage, sortOrder } = paginationState
    const { data: valuations, meta: { stats } } = (
      await Valuation
        .where({ investmentId: this.investment.id })
        .order(sortOrder === 1 ? 'date' : { date: 'desc' })
        .order({ id: 'desc' })
        .includes([
          'operations',
          'methodTemplate'
        ])
        .page(currentPage)
        .per(recordsPerPage)
        .stats({ total: 'count' })
        .all())
    valuations.forEach((val) => { val.date = formatAppDate(val.date) })
    this.valuations = valuations
    return { stats, recordsPerPage }
  }

  updateTablePageState (tablePageState: TablePageState) {
    this.totalRecords = tablePageState.stats.total.count
    this.totalPages = Math.ceil(this.totalRecords / tablePageState.recordsPerPage)
  }

  async saveValuation (payload: ResetTablePayload) {
    const { valuation, paginationState } = payload
    valuation.date = dayjs(valuation.date).format('YYYY-MM-DD')
    await valuation.save()
    await this.saveNewOperations(valuation)
    await this.setTableData(paginationState)
  }

  async saveNewOperations (valuation: Valuation) {
    if (valuation === this.newValuation) {
      const { operations } = valuation
      if (!operations.length) return
      for await (const operation of operations) {
        operation.operationalId = valuation.id!
        operation.save()
      }
    }
  }

  async deleteValuation (payload: ResetTablePayload) {
    const { valuation, paginationState } = payload
    await this.nullifyFlagUponDeletion(valuation)
    await valuation.destroy()
    await this.setTableData(paginationState)
  }

  async nullifyFlagUponDeletion (valuation: Valuation) {
    if (valuation.id === this.investment.flaggedValuationId) {
      this.investment.flaggedValuationId = null
      await this.investment.save()
    }
  }

  async saveFlaggedValuationId (valuation: Valuation) {
    this.investment.flaggedValuationId = valuation.id!
    await this.investment.save()
  }

  async handleUserFlaggedValuation (valuation: Valuation) {
    await this.saveFlaggedValuationId(valuation)
    this.flaggedValuation = valuation
  }

  async flagMostRecentValuation () {
    if (this.mostRecentlyDatedValuation) {
      await this.saveFlaggedValuationId(this.mostRecentlyDatedValuation)
    }
  }

  setFlaggedValuation () {
    if (!this.valuations.length) {
      this.flaggedValuation = null
      return
    }

    const flaggedValuation = Valuation.findRecordInArray(this.valuations, { id: this.investment.flaggedValuationId })
    this.flaggedValuation = flaggedValuation || null
  }

  async onPageViewChange (paginationState: PaginationState) {
    this.loading = true
    const tablePageState = await this.getValuations(paginationState)
    this.updateTablePageState(tablePageState)
    this.setFlaggedValuation()
    this.loading = false
  }

  @Watch('investment.id')
  async getNewValuations () {
    await this.setTableData(this.initialPaginationState)
  }
}
