



























































import { Component, Prop, Watch, Emit, Vue } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'

import Investment from '@/models/investment'

import InvestmentContent from './InvestmentContent.vue'
import InvestmentForm from './InvestmentForm.vue'
import AppDialog from '@/components/AppDialog'

Vue.component('InvestmentContent', InvestmentContent)
Vue.component('InvestmentForm', InvestmentForm)
Vue.component('AppDialog', AppDialog)

const Auth = namespace('auth')

@Component
export default class InvestmentDetailSection extends Vue {
  @Prop() investment!: Investment
  @Prop() newMode!: boolean
  @Auth.Getter currentPermissions!: string

  editMode = this.newMode
  showInactiveConfirmationDialog = false

  enableUpdate = false

  @Watch('investment')
  investmentChanged () {
    this.editMode = false
    Vue.nextTick(() => {
      this.editMode = this.newMode
    })
  }

  mounted () {
    this.getCurrentPermissions()
  }

  getCurrentPermissions () {
    const permissions = this.currentPermissions
    if (!permissions) return

    this.enableUpdate = permissions.includes('update_investment')
  }

  enterEditMode () {
    this.editMode = true
  }

  @Emit()
  async updateActiveStatus (status: boolean) {
    this.investment.active = status
    const success = await this.investment.save()
    this.showInactiveConfirmationDialog = false
    return success
  }

  @Emit()
  exitEditMode (investment: Investment = this.investment) {
    this.editMode = false
    return investment
  }
}
