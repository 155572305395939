var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppDialog',{attrs:{"header":"You're deleting an uploaded attachment.","content":"Are you sure you want to delete this attachment?","show":_vm.showAttachmentDeleteDialog,"buttons":[
      {
        label: 'No',
        action: function () { _vm.showAttachmentDeleteDialog = false },
        class: 'p-button-secondary'
      }, {
        label: 'Yes',
        action: _vm.deleteAttachment,
        class: 'p-button-outlined p-button-danger'
      }
    ]},on:{"hide":function($event){_vm.showAttachmentDeleteDialog = false}}}),_c('DataTable',{staticClass:"p-datatable-sm",attrs:{"id":"attachments_table","value":_vm.attachments,"sortField":"createdAt","sortOrder":-1,"lazy":true,"scrollable":true,"scrollHeight":(function () { return 'calc(60vh - 150px)' })()},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"p-my-3 p-text-center"},[_c('i',[_vm._v("No attached documents")])])]},proxy:true}])},[_c('Column',{attrs:{"field":"createdAt","header":"Uploaded","headerStyle":"width: 2em; pointer-events: none","sortable":true,"sortField":"createdAt"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm._f("appDate")(slotProps.data.createdAt))+" @ "+_vm._s(_vm._f("appTime")(slotProps.data.createdAt))+" ")]}}])}),_c('Column',{attrs:{"header":"Name","headerStyle":"width: 7em"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.blob.filename)+" ")]}}])}),_c('Column',{attrs:{"headerStyle":"text-align: right; width: 2em"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('div',{staticClass:"p-text-right"},[_c('Button',{staticClass:"p-button-text p-button-secondary p-mr-2",attrs:{"title":"Download","icon":"pi pi-download"},on:{"click":function($event){return _vm.downloadAttachment(slotProps.data)}}}),_c('Button',{staticClass:"p-button-danger p-button-text p-mr-2",attrs:{"title":"Delete","icon":"pi pi-trash"},on:{"click":function($event){return _vm.promptForDeleteAttachment(slotProps.data)}}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }