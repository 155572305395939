













































import { MenuType } from '@/types/valuations'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Valuation, Operation } from '@/models'

import Button from 'primevue/button'
import Menu from 'primevue/menu'

Vue.component('Button', Button)
Vue.component('Menu', Menu)

@Component
export default class CalculatorActions extends Vue {
  @Prop() valuation!: Valuation
  @Prop() operations!: Operation[]
  @Prop() operation!: Operation
  @Prop() isTopRow!: boolean
  @Prop() rowIndex!: number

  refKey = this.operation.id
    ? 'operations-actions-menu-' + this.operation.id
    : 'operations-actions-menu-default'

  toggleActionItems (event: Event) {
    const menu: MenuType = this.$refs[this.refKey] as MenuType
    menu.toggle(event)
  }

  get showMenuTooltip () {
    return this.operation.sequenceIndex === 0 && this.operation.amount === null
      ? 'Add an amount to unlock actions'
      : null
  }

  get isAmountValid () {
    return this.operation.amount !== null
  }

  get isTopRowValid () {
    return this.isTopRow && this.isAmountValid
  }

  get insertBelowDisabled () {
    return this.isTopRow && !this.valuation.methodTemplateId && this.operation.amount === null
  }

  @Emit()
  insertRow (insertionIndex: number) {
    this.valuation.removeTemplateReferences()
    const newOperations = Operation.insertRecordAtIndex(
      this.operations,
      insertionIndex,
      {
        operationalType: 'Valuation',
        operationalId: this.valuation?.id || '',
        sequenceIndex: insertionIndex
      })
    return newOperations
  }

  @Emit()
  deleteThisRow () {
    this.valuation.removeTemplateReferences()
    const newOperations = Operation.destroyOperationAtIndex(this.operations, this.rowIndex)
    return newOperations
  }
}
