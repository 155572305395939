










import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import AppDialog from '@/components/AppDialog'
Vue.component('AppDialog', AppDialog)

@Component
export default class AppConfirmDeleteDialog extends Vue {
  @Prop() itemType!: string
  @Prop() show!: boolean

  buttons = [
    {
      label: 'No',
      action: this.cancelDelete,
      class: 'p-button-secondary'
    },
    {
      label: 'Yes',
      action: this.confirmDelete,
      class: 'p-button-outlined p-button-danger'
    }
  ]

  get header () {
    const vowels = ['A', 'E', 'I', 'O', 'U']
    const firstLetterOfItem = this.itemType.charAt(0).toUpperCase()
    const determiner = vowels.includes(firstLetterOfItem) ? 'an' : 'a'
    return `You're deleting ${determiner} ${this.itemType}.`
  }

  @Emit()
  cancelDelete (): null { return null }

  @Emit()
  confirmDelete (): null { return null }
}
