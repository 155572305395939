









import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import formatAppDate from '@/utils/formatAppDate'

import Calendar from 'primevue/calendar'
import Valuation from '@/models/valuation'
Vue.component('Calendar', Calendar)

@Component
export default class ValuationCalendarInput extends Vue {
  @Prop() valuation!: Valuation

  isTopRow = !!this.valuation.id
  originalDate = this.valuation.date

  onBlurDate () {
    if (!this.dateIsValid) this.restorePreviousDate()
    this.change()
  }

  restorePreviousDate () {
    if (this.isTopRow) {
      this.valuation.date = this.originalDate
    } else {
      const originalDate = this.valuation.changes().date?.[0]
      const formattedOriginalDate = formatAppDate(originalDate!)
      this.valuation.date = formattedOriginalDate
    }
  }

  get dateIsValid () {
    const isSelected = this.valuation.date instanceof Date
    if (isSelected) {
      this.valuation.date = formatAppDate(this.valuation.date)
      return true
    }
    const date = new Date(this.valuation.date)
    const reformattedDateString = formatAppDate(date)
    if (reformattedDateString.length !== 10) return false
    if (reformattedDateString.charAt(2) !== '/') return false
    if (reformattedDateString.charAt(5) !== '/') return false
    return this.valuation.date === reformattedDateString
  }

  @Emit()
  change () {
    return [this.dateIsValid, this.valuation]
  }
}
