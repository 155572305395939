










import { Component, Prop, Watch, Emit, Vue } from 'vue-property-decorator'

@Component
export default class CalculatorCloseButton extends Vue {
  @Prop() rows!: any

  watchedRenderEvent = false
  changesExist = false

  mounted () {
    this.changesExist = false
  }

  handleCloseClick () {
    if (this.changesExist) {
      this.unsavedChanges()
    } else {
      this.closeOverlay()
    }
  }

  @Watch('rows', { deep: true })
  checkCalculatorForChanges (): void {
    if (!this.watchedRenderEvent) {
      this.watchedRenderEvent = true
      return
    }
    this.changesExist = true
  }

  @Emit()
  unsavedChanges () { return null }

  @Emit()
  closeOverlay () { return null }
}
