


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Valuation, Operation } from '@/models'

import CalculatorOperatorButton from './CalculatorOperatorButton.vue'
import Button from 'primevue/button'

Vue.component('CalculatorOperatorButton', CalculatorOperatorButton)
Vue.component('Button', Button)

@Component
export default class CalculatorAmountInput extends Vue {
  @Prop() valuation!: Valuation
  @Prop() operation!: Operation
  @Prop() index!: any

  validate (amount: number | null) {
    if (this.operation.operator === '/' && amount === 0) {
      this.operation.amount = null
    }
  }

  @Watch('operation.operator')
  divisionOperatorValidation () {
    this.validate(this.operation.amount)
  }
}
