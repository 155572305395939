


















































































































































































































































































































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import dayjs from 'dayjs'

import Investment from '@/models/investment'
import InvestmentTransaction from '@/models/investment_transaction'

import AppInputCurrency from '@/components/AppInputCurrency'
import AppConfirmDeleteDialog from '@/components/AppConfirmDeleteDialog'
import DataTable from 'primevue/datatable'
import InputText from 'primevue/inputtext'
import Tooltip from 'primevue/tooltip'
import Column from 'primevue/column'

Vue.component('AppInputCurrency', AppInputCurrency)
Vue.component('AppConfirmDeleteDialog', AppConfirmDeleteDialog)
Vue.component('DataTable', DataTable)
Vue.component('InputText', InputText)
Vue.component('Tooltip', Tooltip)
Vue.component('Column', Column)

interface PrimeVueRowSelectEvent {
  data: {
    id: string;
    uid: string;
  };
}

interface PrimeVuePageEvent {
  page: number;
  rows: number;
}

interface PrimeVueRowEditEvent {
  index: number;
  data: InvestmentTransaction;
}

interface PrimeVueSortEvent {
  sortField: string;
  sortOrder: number;
}

@Component
export default class AppInvestmentTransactionsTable extends Vue {
  @Prop() investmentParam!: Investment[]
  @Prop() investmentTypeParam!: string
  @Prop() transactionDateParam!: (string | null)[] | null
  @Prop({ default: () => { return true } }) enableEdit!: boolean
  @Prop({ default: () => { return true } }) enableCreate!: boolean
  @Prop({ default: () => { return true } }) enableDelete!: boolean
  @Prop({ default: () => { return 263 } }) scrollHeightDiff!: number
  @Prop({ default: () => { return 'investments' } }) containingPage!: string
  @Prop({ default: () => { return ['investmentName', 'investmentType'] } }) hiddenColumns!: string[]
  @Prop({
    default: () => {
      return [
        'transactionDate',
        'contributionAmount',
        'distributionAmount',
        'isJournalEntry',
        'memo'
      ]
    }
  }) editableFields!: string[]

  loading = true
  investmentTransactions: InvestmentTransaction[] = []
  editingTransactions: InvestmentTransaction[] = []
  selectedTransactions: InvestmentTransaction[] = []
  newTransaction: InvestmentTransaction = new InvestmentTransaction()
  transactionPendingDelete: InvestmentTransaction | null = null

  showDeleteDialog = false

  page = 1
  pageSize = 50
  totalRecords = 0
  sortField = 'transactionDate'
  sortOrder = -1

  showTable = true

  get investment () {
    if (this.investmentParam && this.investmentParam.length === 1) {
      return this.investmentParam[0]
    }
    return null
  }

  get isLendingType () {
    if (!this.investment) return false
    return this.investment.investmentType === 'lending'
  }

  get newTransactionIsValid () {
    return this.isValid(this.newTransaction)
  }

  get formattedTransactionDateParam () {
    if (!this.transactionDateParam) return null
    const endDate = dayjs(this.transactionDateParam[1] as string).format('YYYY-MM-DD')
    const formattedTransactionDateParam: { lte: string | null; gte: string | null } = {
      lte: endDate,
      gte: null
    }
    if (this.transactionDateParam[0] !== null) {
      const startDate = dayjs(this.transactionDateParam[0] as string).format('YYYY-MM-DD')
      formattedTransactionDateParam.gte = startDate
    }
    return formattedTransactionDateParam
  }

  @Watch('investmentParam')
  async investmentParamChanged () {
    this.showTable = false
    this.loading = true
    Vue.nextTick(async () => {
      this.showTable = true
      await this.getInvestmentTransactions()
      this.resetNewTransaction()
      this.resetEditingTransactions()
      this.loading = false
    })
  }

  @Watch('transactionDateParam')
  async transactionDateParamChanged () {
    // do nothing if user is in the middle of selecting date range
    const value = this.transactionDateParam
    if (!value) return
    if (value.length === 2 && value[0] && !value[1]) return

    this.showTable = false
    this.loading = true
    Vue.nextTick(async () => {
      this.showTable = true
      await this.getInvestmentTransactions()
      this.resetNewTransaction()
      this.resetEditingTransactions()
      this.loading = false
    })
  }

  @Watch('investmentTypeParam')
  async investmentTypeParamChanged () {
    this.showTable = false
    this.loading = true
    Vue.nextTick(async () => {
      this.showTable = true
      await this.getInvestmentTransactions()
      this.resetNewTransaction()
      this.resetEditingTransactions()
      this.loading = false
    })
  }

  async mounted () {
    await this.getInvestmentTransactions()
    this.resetNewTransaction()
    this.resetEditingTransactions()
  }

  formattedSortParam (sortField: string = this.sortField, sortOrder: number = this.sortOrder) {
    let sortParam: { [key: string]: string } = {}
    if (sortField) {
      sortParam[sortField] = sortOrder === 1 ? 'asc' : 'desc'
    } else {
      sortParam = {}
    }
    return JSON.parse(JSON.stringify(sortParam))
  }

  isValid (t: InvestmentTransaction) {
    if (t.transactionDate === undefined || t.transactionDate === null || String(t.transactionDate) === '') return false
    if (!Date.parse(String(t.transactionDate))) return false
    if (!t.contributionAmount && !t.distributionAmount) return false
    if (this.investment && this.investment.investmentType === 'lending') {
      if (t.interestRate === undefined || t.interestRate === null) return false
    }
    return true
  }

  async getInvestmentTransactions (page = 1, pageSize = this.pageSize) {
    this.page = page
    this.pageSize = pageSize

    const response = await InvestmentTransaction
      .includes('investment')
      .where({
        investmentId: this.investmentParam.length > 0 ? this.investmentParam.map(item => { return item.id }) : null,
        investmentType: this.investmentTypeParam ? { eq: this.investmentTypeParam } : null,
        transactionDate: this.formattedTransactionDateParam
      })
      .order(this.formattedSortParam())
      .order(this.formattedSortParam('id', -1 * this.sortOrder))
      .page(this.page)
      .per(this.pageSize)
      .stats({ total: 'count' })
      .all()

    this.investmentTransactions = response.data
    this.totalRecords = response.meta.stats.total.count

    this.loading = false
  }

  async getLatestInterestRate () {
    if (!this.isLendingType || !this.investment) return
    const interestRateResponse = await Investment.find(this.investment.id as string)
    this.investment.latestInterestRate = interestRateResponse.data.latestInterestRate
  }

  onRowSelect (event: PrimeVueRowSelectEvent) {
    const editingIds = this.editingTransactions.map(e => e.id)
    if (!event.data.id || !editingIds.includes(event.data.id)) {
      this.cancelCurrentEditModes()
    }
  }

  resetSelectedTransactions (transaction: InvestmentTransaction | undefined = undefined) {
    if (transaction) {
      this.selectedTransactions = [this.newTransaction, transaction]
    } else {
      this.selectedTransactions = [this.newTransaction]
    }
  }

  resetEditingTransactions (transaction: InvestmentTransaction | undefined = undefined) {
    if (transaction) {
      this.editingTransactions = [this.newTransaction, transaction]
    } else {
      this.editingTransactions = [this.newTransaction]
    }
  }

  async resetNewTransaction () {
    if (!this.investment) return
    this.newTransaction = new InvestmentTransaction({
      transactionDate: new Date(),
      investmentId: this.investment.id
    })
    if (this.investment.investmentType === 'lending') {
      await this.getLatestInterestRate()
      this.newTransaction.interestRate = this.investment.latestInterestRate
    }
    this.revalidateContribution(this.newTransaction)
    this.revalidateDistribution(this.newTransaction)
    if (this.investment.investmentType === 'lending') {
      this.revalidateRepayment(this.newTransaction)
      this.revalidateInterest(this.newTransaction)
    }
  }

  preFormatDateField (transaction: InvestmentTransaction) {
    transaction.transactionDate = new Date(dayjs(transaction.transactionDate as Date).format('MM/DD/YYYY'))
  }

  async onRowEditInit (event: PrimeVueRowEditEvent) {
    await this.cancelCurrentEditModes()

    if (this.investmentTransactions[event.index].id !== undefined) {
      this.resetSelectedTransactions(this.investmentTransactions[event.index])
      this.resetEditingTransactions(this.investmentTransactions[event.index])
      this.preFormatDateField(this.investmentTransactions[event.index])
    } else {
      this.resetSelectedTransactions()
      this.resetEditingTransactions()
    }
  }

  async promptForDelete (transaction: InvestmentTransaction) {
    if (!transaction) return
    this.transactionPendingDelete = transaction
    this.showDeleteDialog = true

    this.cancelCurrentEditModes()
    this.resetSelectedTransactions(transaction)
    this.resetEditingTransactions()
  }

  async saveTransaction (transaction: InvestmentTransaction) {
    if (!transaction) return

    const wasNew = transaction.type && transaction.type === 'new'

    const success = await transaction.save()

    if (!success) {
      // TODO: implement error dialog
      return
    }

    this.getInvestmentTransactions(this.page)

    if (wasNew) this.resetNewTransaction()
    this.resetEditingTransactions()
    this.resetSelectedTransactions(transaction)
    if (this.investment && this.investment.investmentType === 'lending') {
      await this.getLatestInterestRate()
      this.newTransaction.interestRate = this.investment.latestInterestRate
    }
  }

  async deleteTransaction (transaction: InvestmentTransaction | undefined) {
    if (!transaction) {
      if (!this.transactionPendingDelete) { return }
      transaction = this.transactionPendingDelete
    }

    const success = await transaction.destroy()

    if (!success) {
      // TODO: implement error dialog
      return
    }

    this.showDeleteDialog = false
    this.getInvestmentTransactions(this.page)
    if (this.investment && this.investment.investmentType === 'lending') {
      await this.getLatestInterestRate()
      this.newTransaction.interestRate = this.investment.latestInterestRate
    }
  }

  async cancelCurrentEditModes () {
    for (let i = 0; i < this.editingTransactions.length; i++) {
      const e = this.editingTransactions[i]

      let idx = -1
      for (let i = 0; i < this.investmentTransactions.length; i++) {
        const t = this.investmentTransactions[i]
        if (t.id === e.id) {
          idx = i
          break
        }
      }

      if (idx >= 0) {
        const freshTransaction: InvestmentTransaction = (await InvestmentTransaction
          .includes('investment')
          .find(e.id as string)
        ).data
        this.$set(this.investmentTransactions, idx, freshTransaction)
        this.resetEditingTransactions()
      }
    }
  }

  sortBy (event: PrimeVueSortEvent) {
    this.sortField = event.sortField
    this.sortOrder = event.sortOrder
    this.getInvestmentTransactions()
  }

  onPage (event: PrimeVuePageEvent) {
    this.getInvestmentTransactions(event.page + 1, event.rows)
  }

  revalidateContribution (t: InvestmentTransaction) {
    const contributionPresent = this.computeContributionPresent(t)
    if (contributionPresent) {
      t.distributionAmount = null
      if (this.investment && this.investment.investmentType === 'lending') {
        t.repaymentAmount = null
        t.interestAmount = null
      }
    }
    if (!contributionPresent) t.contributionAmount = null
  }

  revalidateDistribution (t: InvestmentTransaction) {
    const distributionPresent = this.computeDistributionPresent(t)
    if (distributionPresent) t.contributionAmount = null
    if (!distributionPresent) t.distributionAmount = null
  }

  revalidateRepayment (t: InvestmentTransaction) {
    if (this.investment && this.investment.investmentType !== 'lending') return

    t.contributionAmount = null

    if (t.type === 'derived') {
      const distributionPresent = this.computeDistributionPresent(t)
      if (distributionPresent) {
        t.interestAmount = Number(t.distributionAmount || 0.0) - Number(t.repaymentAmount || 0.0)
      }
    } else {
      t.distributionAmount = Number(t.repaymentAmount || 0.0) + Number(t.interestAmount || 0.0)
      const distributionPresent = this.computeDistributionPresent(t)
      if (!distributionPresent) t.distributionAmount = null
    }
    const repaymentPresent = this.computeRepaymentPresent(t)
    if (!repaymentPresent) t.repaymentAmount = null
  }

  revalidateInterest (t: InvestmentTransaction) {
    if (this.investment && this.investment.investmentType !== 'lending') return

    t.contributionAmount = null

    if (t.type === 'derived') {
      const distributionPresent = this.computeDistributionPresent(t)
      if (distributionPresent) {
        t.repaymentAmount = Number(t.distributionAmount || 0.0) - Number(t.interestAmount || 0.0)
      }
    } else {
      t.distributionAmount = Number(t.repaymentAmount || 0.0) + Number(t.interestAmount || 0.0)
      const distributionPresent = this.computeDistributionPresent(t)
      if (!distributionPresent) t.distributionAmount = null
    }
    const interestPresent = this.computeInterestPresent(t)
    if (!interestPresent) t.interestAmount = null
  }

  computeRepaymentPresent (t: InvestmentTransaction) {
    return t.repaymentAmount !== undefined && t.repaymentAmount !== null && t.repaymentAmount !== 0
  }

  computeInterestPresent (t: InvestmentTransaction) {
    return t.interestAmount !== undefined && t.interestAmount !== null && t.interestAmount !== 0
  }

  computeContributionPresent (t: InvestmentTransaction) {
    return t.contributionAmount !== undefined && t.contributionAmount !== null && t.contributionAmount !== 0
  }

  computeDistributionPresent (t: InvestmentTransaction) {
    return t.distributionAmount !== undefined && t.distributionAmount !== null && t.distributionAmount !== 0
  }
}
