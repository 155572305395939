














import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import Dropdown from 'primevue/dropdown'

import { Valuation, MethodTemplate } from '@/models'

Vue.component('Dropdown', Dropdown)

@Component
export default class CalculatorTemplateDropdown extends Vue {
  @Prop() valuation!: Valuation
  @Prop() methodTemplates!: MethodTemplate[]

  get selectedTemplate () {
    return this?.valuation.methodTemplateId
      ? MethodTemplate.findRecordInArray(this.methodTemplates, { id: this.valuation.methodTemplateId })
      : 'Select a template'
  }

  @Emit()
  onSelectTemplate (methodTemplate: MethodTemplate) {
    this.valuation.methodTemplateId = methodTemplate.id
    this.valuation.methodTemplate = methodTemplate
    const newTemplateOperations = MethodTemplate.mapTemplateToOperations(
      this.valuation.methodTemplate!.operations,
      { operationalType: 'Valuation', operationalId: this.valuation.id || '' }
    )
    return newTemplateOperations
  }
}
