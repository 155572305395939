




import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import roundToCents from '@/utils/roundToCents'
import Operation from '@/models/operation'

@Component
export default class CalculatorSubtotalInput extends Vue {
  @Prop() operation!: Operation
  @Prop() rows!: Operation[]
  @Prop() isFirstSubtotal!: boolean
  @Prop() isFinalSubtotal!: boolean
  @Prop() index!: any

  subtotal: number | null = null

  beforeMount () {
    this.updateSubtotals()
  }

  get subtotalDisplay () {
    return (this.checkForNullAmount() || this.subtotal === null) || this.isFirstSubtotal
      ? ''
      : this.subtotal
  }

  updateSubtotals () {
    this.subtotal = this.calculateSubtotal()
    if (this.isFinalSubtotal) this.reportValuation(this.subtotal)
  }

  operate (runningTotal: any, operator: string, currentAmount: any) {
    if (runningTotal === null || !operator || currentAmount === null) return runningTotal
    if (operator === '+') return runningTotal + currentAmount
    if (operator === '-') return runningTotal - currentAmount
    if (operator === '*') return runningTotal * currentAmount
    if (operator === '/') return roundToCents(runningTotal / currentAmount)
  }

  calculateSubtotal () {
    if (this.isFirstSubtotal) {
      return this.operation.amount
    }

    const rowsThroughCurrentIndex = [...this.rows].slice(0, this.index + 1)
    const subtotal: number = rowsThroughCurrentIndex.reduce((runningTotal, operation) => {
      const operator: string = operation.operator
      const amount: any = operation.amount
      const nextSubtotal = typeof amount !== 'number'
        ? runningTotal
        : this.operate(runningTotal, operator, amount)
      return nextSubtotal
    }, 0)
    return subtotal
  }

  checkForNullAmount () { return this.operation.amount === null }

  @Emit()
  reportValuation (amount: number | null) {
    return amount
  }

  @Watch('rows', { deep: true })
  updateTotalValuation () {
    this.updateSubtotals()
  }
}
