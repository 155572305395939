



















import { Component, Emit, Inject, Prop, Vue, Watch } from 'vue-property-decorator'
import { MethodTemplate, Operation, Valuation } from '@/models'

import Button from 'primevue/button'
Vue.component('Button', Button)

@Component
export default class CalculatorButtonGroup extends Vue {
  @Inject() readonly calculatingTopRow!: boolean
  @Prop() initialMethodTemplate!: MethodTemplate | null
  @Prop() initialOperationsCopy!: Operation[]
  @Prop() valuation!: Valuation
  @Prop() rows!: Operation[]

  watchedRenderEvent = false
  changesExist = false

  mounted () {
    this.changesExist = false
  }

  get checkCalculatorDefaultState () {
    const { amount: firstRowAmount, label: firstRowLabel } = this.rows[0]
    return this.rows.length === 1 && !firstRowAmount && !firstRowLabel
  }

  get saveButtonDisabled () {
    return typeof this.rows?.[0]?.amount !== 'number'
  }

  handleClearClick () {
    if (this.checkCalculatorDefaultState && !this.changesExist) {
      this.closeOverlay()
    } else {
      this.openClearCalculatorDialog()
    }
  }

  async handleSaveClick () {
    if (!this.changesExist) return this.closeOverlay()
    this.calculatingTopRow
      ? this.attachOperationsWithoutSaving()
      : this.saveOperations()
  }

  @Emit()
  attachOperationsWithoutSaving () {
    if (!this.valuation!.id && !this.initialMethodTemplate) {
      this.initialMethodTemplate = this.valuation.methodTemplate!
    }
    const saveableOperations = this.rows.filter((operation: Operation) => operation.amount !== null)
    return saveableOperations
  }

  @Emit()
  async saveOperations (): Promise<Operation[]> {
    const operationsToDelete = this.initialOperationsCopy.filter(operation => {
      const operationStillUsed = this.rows.find(newOperation => newOperation.id === operation.id!)
      return !operationStillUsed
    })
    await Operation.deleteRecords(operationsToDelete)
    const newOperations = await Operation.saveRecordsWithAmounts(this.rows)
    return newOperations
  }

  @Watch('rows', { deep: true })
  checkCalculatorForChanges () {
    if (!this.watchedRenderEvent) {
      this.watchedRenderEvent = true
      return
    }
    this.changesExist = true
  }

  @Emit()
  openClearCalculatorDialog () { return null }

  @Emit()
  closeOverlay () { return null }
}
