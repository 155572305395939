








































import { Component, Emit, Prop, Provide, Vue, Watch } from 'vue-property-decorator'
import { Valuation, Operation } from '@/models'
import { RowState } from '@/types/valuations'

import AppInputCurrency from '@/components/AppInputCurrency'
import Calculator from './Calculator.vue'

import OverlayPanel from 'primevue/overlaypanel'
import InputNumber from 'primevue/inputnumber'
import Button from 'primevue/button'

Vue.component('AppInputCurrency', AppInputCurrency)
Vue.component('Calculator', Calculator)
Vue.component('OverlayPanel', OverlayPanel)
Vue.component('InputNumber', InputNumber)
Vue.component('Button', Button)

@Component
export default class ValuationValueCell extends Vue {
  @Prop() valuationBeingCalculated!: Valuation
  @Prop() valueInputDisabled!: boolean
  @Prop() isNewValuation!: boolean
  @Prop() valuation!: Valuation
  @Prop() isBodySlot!: boolean
  @Prop() rowState!: RowState

  overlayRef = ''

  beforeMount () {
    this.overlayRef = 'calculator_overlay_' + (this.valuation.id || 'new-valuation')
  }

  get buttonId () {
    return !this.valuation.id ? 'top-calculator-icon' : 'calculator-icon-' + this.valuation.id
  }

  get isValueSetByCalculator () {
    return this.valuation.operations.length > 0
  }

  @Emit()
  showCalculator (event: Event | null) {
    if (!this.valuationBeingCalculated) this.valuationBeingCalculated = this.valuation
    const overlayElement = this.$refs?.[this.overlayRef] as any
    if (overlayElement) {
      overlayElement.show(event)
    }
    return this.valuation
  }

  closeOverlay () {
    const overlayElement = this.$refs?.[this.overlayRef] as any
    if (overlayElement) overlayElement.hide()
  }

  overrideInvestorEquity () {
    const { value: v, equityPercent: iep, investorEquity: ie } = this.rowState.valid
    const onlyInvestorEquityHasInput = !v && !iep && ie
    if (onlyInvestorEquityHasInput) {
      this.valuation.investorEquity = null
      this.valuation.equityPercent = 100
    }
  }

  setValueFromCalculator (newValue: number) {
    this.overrideInvestorEquity()
    this.valuation.value = newValue
    this.change()
  }

  setNewValuationRowValue ([operations, totalValue]: [Operation[], number]) {
    this.valuation.operations = operations
    this.setValueFromCalculator(totalValue)
    this.closeOverlay()
  }

  handleCalculatorCleared () {
    this.valuation.operations = []
    this.valuation.removeTemplateReferences()
    this.valuation.equityPercent = null
    this.valuation.value = null
    this.closeOverlay()
    this.change()
    if (!this.isNewValuation) this.saveValuation()
  }

  @Emit()
  saveValuation () {
    return this.valuation
  }

  @Emit()
  async handleSaveOperations (newValue: any): Promise<Valuation | void> {
    this.setValueFromCalculator(newValue)
    this.closeOverlay()
    if (!this.isNewValuation) {
      return this.valuation
    }
  }

  @Watch('valuationBeingCalculated')
  closeThisOverlayOnNewOverlayOpen () {
    if (this.valuationBeingCalculated !== this.valuation) this.closeOverlay()
  }

  @Emit()
  change () {
    return [this.valuation.value, this.valuation, 'value']
  }

  @Provide() calculatingTopRow = this.isNewValuation
}
