

































































































































































































































































import {
  RowState,
  PaginationState,
  ResetTablePayload,
  PrimeVuePageEvent
} from '@/types/valuations'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import isValidNumberInput from '@/utils/isValidNumberInput'

import { Valuation } from '@/models'

import dayjs from 'dayjs'

import Column from 'primevue/column'
import Tooltip from 'primevue/tooltip'

import AppConfirmDeleteDialog from '@/components/AppConfirmDeleteDialog'
import AppFileManager from '@/components/AppFileManager'
import AppInputCurrency from '@/components/AppInputCurrency'

import ValuationCalendarInput from './ValuationCalendarInput.vue'
import ValuationValueCell from './ValuationValueCell.vue'
import ValuationActionButtons from './ValuationActionButtons.vue'

Vue.component('Column', Column)
Vue.component('AppInputCurrency', AppInputCurrency)
Vue.component('AppConfirmDeleteDialog', AppConfirmDeleteDialog)
Vue.component('AppConfirmDeleteDialog', AppConfirmDeleteDialog)
Vue.component('AppFileManager', AppFileManager)
Vue.component('ValuationCalendarInput', ValuationCalendarInput)
Vue.component('ValuationValueCell', ValuationValueCell)
Vue.component('ValuationActionButtons', ValuationActionButtons)

Vue.directive('Tooltip', Tooltip)

@Component
export default class ValuationsTable extends Vue {
  @Prop() newValuation!: Valuation
  @Prop() valuations!: Valuation[]
  @Prop() flaggedValuation!: Valuation | null
  @Prop() totalRecords!: number
  @Prop() loading!: boolean

  editEnabledRows: Valuation[] = [this.newValuation]

  selectedRows: Valuation[] = [this.newValuation]
  showConfirmDeleteDialog = false
  showAttachmentDialog = false

  valuationPendingDeletion: Valuation | null = null
  valuationBeingCalculated: Valuation | null = null
  valuationWithAttachments: Valuation | null = null

  pagination: PaginationState = {
    recordsPerPage: 50,
    currentPage: 1,
    sortOrder: -1
  }

  newRowInputs: RowState = {
    valid: {
      date: true,
      value: false,
      equityPercent: false,
      investorEquity: false
    },
    disabled: {
      value: false,
      equityPercent: false,
      investorEquity: false
    }
  }

  editRowInputs: RowState = {
    valid: { date: true },
    disabled: { }
  }

  resetNewRowInputs () {
    this.newRowInputs = {
      valid: {
        date: true,
        value: false,
        equityPercent: false,
        investorEquity: false
      },
      disabled: {
        value: false,
        equityPercent: false,
        investorEquity: false
      }
    }
  }

  resetEditRowInputs () {
    this.editRowInputs = {
      valid: { date: true },
      disabled: { }
    }
  }

  get isNewRowValid () {
    const { date: dateIsValid, value, equityPercent, investorEquity } = this.newRowInputs.valid
    const numbersAreValid = (value && equityPercent) || (investorEquity)
    return dateIsValid && numbersAreValid
  }

  get isEditRowValid () {
    const { date: dateIsValid, value, equityPercent, investorEquity } = this.editRowInputs.valid
    const numbersAreValid = (value && equityPercent && investorEquity) || (!value && !equityPercent && investorEquity)
    return dateIsValid && numbersAreValid
  }

  get addNewButtonDisabled () {
    return !this.isNewRowValid || this.editEnabledRows.length === 2
  }

  updateRowState (valuation: Valuation) {
    const inputValidity = {
      date: true,
      value: isValidNumberInput(valuation.value),
      equityPercent: isValidNumberInput(valuation.equityPercent),
      investorEquity: isValidNumberInput(valuation.investorEquity)
    }

    const rowState = {
      valid: inputValidity,
      disabled: this.findDisabledNumberInputs(inputValidity)
    }

    if (!valuation.id) {
      this.newRowInputs = rowState
    } else {
      this.editRowInputs = rowState
    }
  }

  findDisabledNumberInputs (inputValidity: { [key: string]: boolean }) {
    const { value, equityPercent, investorEquity } = inputValidity
    const isInvestorEquityManuallyEntered = !value && !equityPercent && investorEquity
    const isInvestorEquityCalculated = value || equityPercent
    const inputsDisabled = {
      value: isInvestorEquityManuallyEntered,
      equityPercent: isInvestorEquityManuallyEntered,
      investorEquity: isInvestorEquityCalculated
    }
    return inputsDisabled
  }

  onDateInput (dateValidity: [boolean, Valuation]) {
    const [valid, valuation] = dateValidity
    this[valuation.id ? 'editRowInputs' : 'newRowInputs'].valid.date = valid
  }

  onNumberInput (numberValidity: [number, Valuation, string]) {
    const [inputValue, valuation, fieldName] = numberValidity
    this[valuation.id ? 'editRowInputs' : 'newRowInputs'].valid[fieldName] = isValidNumberInput(inputValue)

    if (fieldName === 'value' || fieldName === 'equityPercent') {
      valuation.recalculateInvestorEquity()
    }
    this.updateRowState(valuation)
  }

  initEditRow (valuation: Valuation): void {
    this.updateRowState(valuation)
    valuation.date = dayjs(valuation.date).format('MM/DD/YYYY')
    this.editEnabledRows = [this.newValuation, valuation]
    this.selectedRows = [this.newValuation, valuation]
  }

  onCancelButtonClick (valuation: Valuation) {
    const rowDataWasChanged = this.checkValuationForChanges(valuation)
    if (rowDataWasChanged) {
      return this.resetTableData()
    } else {
      this.selectedRows = [this.newValuation]
      this.editEnabledRows = [this.newValuation]
      this.resetEditRowInputs()
    }
  }

  checkValuationForChanges (valuation: any): boolean {
    const changes = valuation.changes()
    if (Object.keys(changes).length === 0) return false
    const [originalDate, newDate] = changes.date
    const dateChanged = newDate !== dayjs(originalDate).format('MM/DD/YYYY')
    const valuationChanged = Object.keys(changes).length > 1 || dateChanged
    return valuationChanged
  }

  revertUnsavedRowChanges (valuationBeingEdited: any): void {
    if (valuationBeingEdited === this.newValuation) return
    if (valuationBeingEdited.isDirty()) {
      const changes = valuationBeingEdited.changes()
      for (const key in changes) {
        valuationBeingEdited[key] = changes[key][0]
      }
    }
  }

  onRowSelect (event: any): void {
    this.selectedRows = [this.newValuation, event.data]
    this.autoCancelIncompleteEdits(event.data)
  }

  autoCancelIncompleteEdits (selectedRow: any): void {
    if (this.editEnabledRows.length === 2) {
      const rowWithIncompleteEdits = this.editEnabledRows[1]
      if (selectedRow !== rowWithIncompleteEdits) {
        this.revertUnsavedRowChanges(rowWithIncompleteEdits)
        this.editEnabledRows = [this.newValuation]
        this.resetEditRowInputs()
      }
    }
  }

  openDeleteDialog (valuation: Valuation) {
    this.selectedRows = [this.newValuation, valuation]
    this.autoCancelIncompleteEdits(valuation)
    this.valuationPendingDeletion = valuation
    this.showConfirmDeleteDialog = true
  }

  openAttachmentDialog (valuation: Valuation) {
    this.selectedRows = [this.newValuation, valuation]
    this.valuationWithAttachments = valuation
    this.showAttachmentDialog = true
  }

  @Emit()
  onHideAttachmentDialog () {
    return null
  }

  documentDisplay (valuation: Valuation) {
    if (valuation.attachmentCount === 0) return
    return valuation.attachmentCount + ' attached document(s)'
  }

  cancelDelete () {
    this.showConfirmDeleteDialog = false
    this.valuationPendingDeletion = null
  }

  handleShowCalculator (valuationBeingCalculated: Valuation) {
    this.selectedRows = [this.newValuation, valuationBeingCalculated]
    this.revertUnsavedRowChanges(valuationBeingCalculated)
    this.editEnabledRows = [this.newValuation]
    this.resetEditRowInputs()
    this.valuationBeingCalculated = valuationBeingCalculated
    this.updateRowState(valuationBeingCalculated)
  }

  handleHideCalculator () {
    this.valuationBeingCalculated = null
    this.selectedRows = [this.newValuation]
  }

  @Emit()
  flagThisValuation (valuation: Valuation): Valuation {
    return valuation
  }

  get getPaginatorTemplate (): string {
    return this.totalRecords > this.pagination.recordsPerPage
      ? 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
      : 'CurrentPageReport'
  }

  get paginatorReportTemplate (): string {
    return this.totalRecords > 0
      ? 'Showing {first} to {last} of {totalRecords} valuations'
      : 'No valuations to show'
  }

  @Emit()
  onPage (event: PrimeVuePageEvent) {
    this.pagination.recordsPerPage = event.rows
    this.pagination.currentPage = event.page + 1
    return this.pagination
  }

  @Emit()
  onSort (): PaginationState {
    this.pagination.sortOrder = -1 * this.pagination.sortOrder
    return this.pagination
  }

  @Emit()
  deleteValuation (): ResetTablePayload {
    this.showConfirmDeleteDialog = false
    return {
      valuation: this.valuationPendingDeletion || new Valuation(),
      paginationState: this.pagination
    }
  }

  @Emit()
  saveValuation (valuation: Valuation): ResetTablePayload {
    this.editEnabledRows = [this.newValuation]
    return { valuation, paginationState: this.pagination }
  }

  restoreTableDefaults () {
    this.editEnabledRows = [this.newValuation]
    this.showConfirmDeleteDialog = false
    this.valuationPendingDeletion = null
    this.valuationBeingCalculated = null
    this.resetNewRowInputs()
    this.resetEditRowInputs()
  }

  @Emit()
  resetTableData (): PaginationState { return this.pagination }

  @Watch('newValuation')
  onNewValuationChanged () { this.restoreTableDefaults() }

  @Watch('valuations')
  onValuationsChanged () { this.restoreTableDefaults() }
}
